import React from "react"

const DisclaimerCard = ({ instructions }) => {
  return (
    <div className="card">
      <div className="disclaimer">
        <p>{instructions}</p>
      </div>
    </div>
  )
}

export default DisclaimerCard
