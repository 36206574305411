import React, { useEffect, useState } from "react"
import { BlogCard } from "."

const Cards = ({ nodes, people }) => {
  const [posts, setPosts] = useState(undefined)
  useEffect(() => {
    const randomPosts = nodes.sort(() => (Math.random() > 0.5 ? 1 : -1))
    setPosts(randomPosts)
  }, [nodes])

  const rand = () => Math.floor(Math.random() * people.length)
  return posts ? (
    <div>
      {posts.map((item, i) => (
        <div key={`card-${item.node.data.slug}`}>
          <BlogCard data={item.node.data} person={people[rand()]} />
        </div>
      ))}
    </div>
  ) : (
    <div></div>
  )
}

export default Cards
