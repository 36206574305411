import React, { useEffect } from "react"
import { createWidget } from "@typeform/embed"
import { Card } from "."
import "@typeform/embed/build/css/widget.css"
import { useSessionContext } from "../utils"
import { logComplete } from "../utils/"

const QuizCard = ({ instructions }) => {
  const { session, tgmSession } = useSessionContext()

  useEffect(() => {
    console.log(session)
    if (window !== undefined && session) {
      createWidget(process.env.GATSBY_APP_TYPEFORM, {
        container: document.querySelector("#typeform"),
        hideHeaders: true,
        hideFooter: true,
        hidden: {
          sessionid: session,
          tgmsession: tgmSession,
        },
        onSubmit: async () => {
          await logComplete(session)
          setTimeout(() => {
            window.location.href = `https://tgm.mobi/status/fin?id=${tgmSession}`
          }, 1000)
        },
      })
    }
  }, [session, tgmSession])

  return (
    <Card claimName="survey">
      <div className="quiz">
        <p>{instructions}</p>
        <div id="typeform"></div>
      </div>
    </Card>
  )
}

export default QuizCard
