import React, { useEffect } from "react"
import { URLParser } from "../utils"

const Footer = ({ footer }) => {
  const footerHTML = URLParser(footer)

  useEffect(() => {
    const footerRef = document.getElementById("footer")
    footerRef.innerHTML = footerHTML
  }, [footerHTML])
  return <footer id="footer" />
}

export default Footer
