import React from "react"
import { Card, User, ImageCard, DetailCard } from "."

const BlogCard = (props) => {
  const { data, person } = props
  return (
    <Card claimName={data.slug}>
      <User person={person} />
      {data.image && data.image.length ? (
        <ImageCard
          image={data.image[0]}
          text={data.claim}
          fontColor={data.fontColor}
        />
      ) : (
        <h1>{data.claim}</h1>
      )}
      {(data.correctText || data.readMore) && <DetailCard data={data} />}
    </Card>
  )
}

export default BlogCard
