import React, { useEffect } from "react"
import { URLParser } from "../utils"

const DetailCard = ({ data }) => {
  const readMore = URLParser(data.readMore)

  useEffect(() => {
    const readRef = document.getElementById(`read-more-${data.slug}`)
    readRef.innerHTML = readMore
  }, [readMore, data.slug])

  return (
    <div className="detail-container">
      <div>{data.correctText}</div>
      <div id={`read-more-${data.slug}`} />
    </div>
  )
}

export default DetailCard
