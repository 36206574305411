import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { useSessionContext, logScroll } from "../utils"

const Card = ({ children, claimName }) => {
  const { ref, inView } = useInView({ threshold: 0.3 })
  const { session, step, setStep, startTime } = useSessionContext()

  useEffect(() => {
    if (inView && session) {
      logScroll(session, claimName, step, startTime).then((res) =>
        setStep(() => res)
      )
    }
  }, [inView, session]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={ref} className="card">
      {children}
    </div>
  )
}

export default Card
